var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c("div", { staticClass: "expense-content-title" }, [
            _c("span", { staticClass: "left" }),
            _c(
              "span",
              {
                staticClass: "expense-col",
                staticStyle: { width: "550px !important" },
              },
              [_vm._v("保险险种名称")]
            ),
            _c("span", { staticClass: "expense-col" }, [_vm._v("免赔额")]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("费率浮动")]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("保险金额")]),
            _c("span", { staticClass: "expense-col" }, [
              _vm._v("车上人员责任保险座位数"),
            ]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("保险费")]),
            _c("span", { staticClass: "right" }),
          ]),
          _c(
            "draggable",
            {
              attrs: { handle: ".item-drag" },
              model: {
                value: _vm.form.coverages,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "coverages", $$v)
                },
                expression: "form.coverages",
              },
            },
            _vm._l(_vm.form.coverages, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "expense-content-item" },
                [
                  _c("div", { staticClass: "left" }, [
                    _vm._v(_vm._s(index + 1)),
                  ]),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      staticStyle: { width: "550px !important" },
                      attrs: {
                        prop: "coverages[" + index + "].insuranceCoverName",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            disabled: _vm.onlyRead,
                            placeholder: "请选择保险险种名称",
                            "allow-clear": "",
                          },
                          model: {
                            value: item.insuranceCoverName,
                            callback: function ($$v) {
                              _vm.$set(item, "insuranceCoverName", $$v)
                            },
                            expression: "item.insuranceCoverName",
                          },
                        },
                        _vm._l(
                          _vm.insuranceCoverNameOptions,
                          function (d, idx) {
                            return _c(
                              "a-select-option",
                              { key: idx, attrs: { value: d.dictValue } },
                              [_vm._v(_vm._s(d.dictLabel))]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "coverages[" + index + "].deductible" },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:number",
                            arg: "number",
                          },
                        ],
                        attrs: {
                          disabled: _vm.onlyRead,
                          placeholder: "请输入",
                          "addon-after": "元",
                        },
                        model: {
                          value: item.deductible,
                          callback: function ($$v) {
                            _vm.$set(item, "deductible", $$v)
                          },
                          expression: "item.deductible",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "coverages[" + index + "].rateFloating" },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:number",
                            arg: "number",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.onlyRead,
                          "addon-after":
                            item.insuranceCoverName === "1" ||
                            item.insuranceCoverName === "5"
                              ? "%"
                              : "元",
                        },
                        model: {
                          value: item.rateFloating,
                          callback: function ($$v) {
                            _vm.$set(item, "rateFloating", $$v)
                          },
                          expression: "item.rateFloating",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: {
                        prop: "coverages[" + index + "].insuranceAmount",
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:number",
                            arg: "number",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.onlyRead,
                          "addon-after": "元",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onPriceChange($event, index)
                          },
                        },
                        model: {
                          value: item.insuranceAmount,
                          callback: function ($$v) {
                            _vm.$set(item, "insuranceAmount", $$v)
                          },
                          expression: "item.insuranceAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: {
                        prop: "coverages[" + index + "].insuranceSeatNum",
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:int",
                            arg: "int",
                          },
                        ],
                        attrs: {
                          disabled: _vm.onlyRead,
                          placeholder: "请输入",
                          "addon-after": "位",
                        },
                        model: {
                          value: item.insuranceSeatNum,
                          callback: function ($$v) {
                            _vm.$set(item, "insuranceSeatNum", $$v)
                          },
                          expression: "item.insuranceSeatNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "coverages[" + index + "].insuranceCost" },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:number",
                            arg: "number",
                          },
                        ],
                        attrs: {
                          disabled: _vm.onlyRead,
                          placeholder: "请输入",
                          "addon-after": "元",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onPriceChange($event, index)
                          },
                        },
                        model: {
                          value: item.insuranceCost,
                          callback: function ($$v) {
                            _vm.$set(item, "insuranceCost", $$v)
                          },
                          expression: "item.insuranceCost",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.onlyRead
                    ? _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("a-icon", {
                            staticClass: "item-add",
                            attrs: { type: "plus-circle" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleAddExpense(index)
                              },
                            },
                          }),
                          index > 0
                            ? _c("a-icon", {
                                staticClass: "item-delete",
                                attrs: { type: "delete" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleDeleteExpense(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }