<template>
  <div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="expense-content-title">
        <span class="left"></span>
        <span class="expense-col" style="width: 550px !important;">保险险种名称</span>
        <span class="expense-col">免赔额</span>
        <span class="expense-col">费率浮动</span>
        <span class="expense-col">保险金额</span>
        <span class="expense-col">车上人员责任保险座位数</span>
        <span class="expense-col">保险费</span>
        <span class="right"></span>
      </div>
      <draggable v-model="form.coverages" handle=".item-drag">
        <div class="expense-content-item" v-for="(item, index) in form.coverages" :key="index">
          <div class="left">{{ index + 1 }}</div>
          <!-- 保险险种名称 -->
          <a-form-model-item
            style="width: 550px !important;"
            :prop="`coverages[${index}].insuranceCoverName`"
            class="expense-col"
          >
            <a-select
              :disabled="onlyRead"
              placeholder="请选择保险险种名称"
              v-model="item.insuranceCoverName"
              allow-clear
            >
              <a-select-option v-for="(d, idx) in insuranceCoverNameOptions" :key="idx" :value="d.dictValue">{{
                d.dictLabel
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 免赔额 -->
          <a-form-model-item :prop="`coverages[${index}].deductible`" class="expense-col">
            <a-input
              :disabled="onlyRead"
              placeholder="请输入"
              v-model="item.deductible"
              addon-after="元"
              v-input-filter:number
            />
          </a-form-model-item>
          <!-- 费率浮动 -->
          <a-form-model-item :prop="`coverages[${index}].rateFloating`" class="expense-col">
            <a-input
              placeholder="请输入"
              :disabled="onlyRead"
              v-model="item.rateFloating"
              :addon-after="item.insuranceCoverName === '1' || item.insuranceCoverName === '5' ? '%' : '元'"
              v-input-filter:number
            />
          </a-form-model-item>
          <!-- 保险金额 -->
          <a-form-model-item :prop="`coverages[${index}].insuranceAmount`" class="expense-col">
            <a-input
              placeholder="请输入"
              :disabled="onlyRead"
              v-model="item.insuranceAmount"
              addon-after="元"
              @change="onPriceChange($event, index)"
              v-input-filter:number
            />
          </a-form-model-item>
          <!-- 车上人员责任保险座位数 -->
          <a-form-model-item :prop="`coverages[${index}].insuranceSeatNum`" class="expense-col">
            <a-input
              :disabled="onlyRead"
              placeholder="请输入"
              v-model="item.insuranceSeatNum"
              addon-after="位"
              v-input-filter:int
            />
          </a-form-model-item>
          <!-- 保险费 -->
          <a-form-model-item :prop="`coverages[${index}].insuranceCost`" class="expense-col">
            <a-input
              :disabled="onlyRead"
              placeholder="请输入"
              v-model="item.insuranceCost"
              addon-after="元"
              @change="onPriceChange($event, index)"
              v-input-filter:number
            />
          </a-form-model-item>

          <div class="right" v-if="!onlyRead">
            <!-- <img :src="dragUrl" class="item-drag" @click="HandleDragExpense(index)" v-if="index > 0" /> -->
            <a-icon type="plus-circle" class="item-add" @click.stop="handleAddExpense(index)" />
            <a-icon type="delete" class="item-delete" @click.stop="handleDeleteExpense(index)" v-if="index > 0" />
          </div>
        </div>
      </draggable>
    </a-form-model>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'ExpenseList',
  props: {
    crules: {
      type: Object,
      required: true
    },
    cform: {
      type: Object,
      required: true
    },
    onlyRead: Boolean
  },
  components: { draggable },
  data() {
    return {
      loading: false,
      rules: this.crules,
      form: this.cform,
      dragUrl: require('@/assets/images/drag.png'),
      unitOptions: ['个', '升', '条', '瓶', '辆', '台'],
      insuranceCoverNameOptions: []
    }
  },
  filters: {},
  created() {
    console.log('created')
    this.getDicts('iot_account_insuranceType').then(response => {
      this.insuranceCoverNameOptions = response.data
    })
  },
  computed: {},
  watch: {
    cform(newVal, oldVal) {
      console.log('cform change', newVal)
      this.form = newVal
    }
  },
  mounted() {
    console.log('cform', this.form)
    this.addRule(0)
  },
  methods: {
    addRule(index) {
      this.rules = this.rules || {}
      if (index >= 0) {
        this.rules[`coverages[${index}].insuranceCoverName`] = [
          { required: true, message: '险种名称不能为空', trigger: 'blur' }
        ]
        this.rules[`coverages[${index}].rateFloating`] = [
          { required: true, message: '费率浮动不能为空', trigger: 'blur' }
        ]
        this.rules[`coverages[${index}].insuranceAmount`] = [
          { required: true, message: '保险金额不能为空', trigger: 'blur' }
        ]
        this.rules[`coverages[${index}].insuranceCost`] = [
          { required: true, message: '保险费不能为空', trigger: 'blur' }
        ]
      }
    },
    handleAddExpense() {
      const index = this.form.coverages.length - 1
      this.form.coverages.splice(index + 1, 0, {
        insuranceCoverName: '',
        rateFloating: '',
        insuranceAmount: '',
        insuranceCost: ''
      })
      this.addRule(this.form.coverages.length - 1)
    },
    handleDeleteExpense(index) {
      if (index < this.form.coverages.length) {
        // 先删除校验规则
        const item = this.form.coverages[index]
        for (const fieldName in item) {
          const key = `coverages[${index}].${fieldName}`
          const rules = this.rules
          for (const r in rules) {
            if (r === key) {
              delete this.rules[key]
              break
            }
          }
        }
        this.form.coverages.splice(index, 1)
      }
    },
    onPriceChange(e, index) {
      this.toSum()
    },
    toSum() {
      let sum = 0
      this.form.coverages.forEach(p => {
        sum += Number(p.insuranceCost)
      })
      this.$forceUpdate()
      this.$emit('setAmount', sum.toFixed(2))
    },
    getCoverages() {
      return this.form.coverages
    },
    HandleDragExpense(index) {
      this.form.coverages[index] = this.form.coverages.splice(index - 1, 1, this.form.coverages[index])[0]
    }
  }
}
</script>
<style lang="less" scoped>
.expense-content-title,
.expense-content-item {
  display: flex;
  .expense-col {
    width: 25% !important;
    margin: 0 3px;
    text-align: center;
  }
  > .left {
    flex: 0 0 35px;
    min-width: 35px;
    text-align: right;
    padding-right: 3px;
  }
  > .right {
    flex: 0 0 100px;
    min-width: 100px;
    display: flex;
  }
}

.expense-content-title {
  .expense-col {
    font-weight: bold;
    text-align: center;
  }
}

.expense-content-item {
  > .left {
    height: 40px;
    line-height: 40px;
  }
  > .right {
    .item-drag,
    .item-add,
    .item-delete {
      font-size: 25px;
      margin: 0 3px;
      height: 40px;
      line-height: 40px;
    }
    .item-add {
      line-height: 45px !important;
    }
  }
}
.item-delete {
  margin-top: 2px !important;
}
.item-drag:hover {
  cursor: pointer;
}
</style>
